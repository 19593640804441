.wrapper {
  height: 100vh;  
  background-color: transparent;  
  color: #c4b4d1;

  display: flex;
  flex-direction: column;
  justify-content: center;    
  font-family: 'HelvCC';
  font-weight: 800;
  cursor: pointer;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;  
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 60rem;
  height: 100vh;
  max-height: 30rem;  

  position: relative;

  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-right: .5rem;
  padding-left: .5rem;
}

.popup-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
}

@media (orientation: landscape) {
  .popup-top-row {
    margin-top: 1rem;
  }
}

.text-collected {
  color: #c4b4d1;
  text-align: center;
  width: 100%;

  position: absolute;
  left: 0;
  top: 1.5rem;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}

.bottom-row {
  display: flex;
  flex-direction: row;

  width: 50rem;
  margin-top: 1rem;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 50%;  
  align-items: center;
}

@media (orientation: landscape) {
  .left-column {
    width: 25%;
  }
}

.ship-image {  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 100%;  
}

.ship-name {
  font-size: 2em;
  font-weight: 800;
}

.right-side {
  width: 50%;
  margin-left: 1rem;

  display: flex;
  flex-direction: column;
}

.stats-title {
  font-size: 1.5em;
  font-weight: 600;
}

.stats-block {
  display: flex;
  flex-direction: column;  
}

.stat-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem;
}

.stat-image {
  width: 1.5rem;
}


.ship-rarity {  
  font-weight: 800;  
  margin-top: .2rem;
  font-size: .8em;
}

.ship-rarity-common {
  color: #9584a6;  
}

.ship-rarity-rare {
  color: #5d83d4;  
}

.ship-rarity-epic {
  color: #710774;  
}

.ship-rarity-legendary {
  color: #e08d38;  
}

.stat-details {
  font-size: 1.5em;
  margin-left: 1rem;

  display: flex;
  flex-direction: row;
  margin-top: .2rem;
}

.stat-base-text {
  color: #c4b4d1;
  font-size: .8em;
}

.stat-upgrade-text {
  color: lightgreen;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.stat-powerup-text {
  color: #5d83d4;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.description-text {
  font-size: 1em;
  margin-top: 2rem;  
  margin-bottom: 2rem;
  text-align: center;
}

.tip-text {
  font-family: "EquinoxBold" !important;
  font-weight: 800 !important;  
}

