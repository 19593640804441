.left-nav {
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 15rem;
  margin-right: 2rem;
  margin-left: 1rem;
}

.player-block {
  display: flex;
  flex-direction: row;  

  margin-right: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.nav-icon {
  width: 3rem;
  height: 3rem;  
}

.player-info {
  display: flex;
  flex-direction: column;

  margin-left: 1rem;
  margin-bottom: 1rem;
}

.player-name {
  font-size: 1em;  
  color: #c4b4d1;
  font-family: 'VoyagerHeavy';
  margin-bottom: .5rem;
}

.player-stats {  
  font-size: 1em;
  color: #c4b4d1;
  
  display: flex;
  flex-direction: row;  
}

.player-stats-box {
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.token-image {
  height: 1.5rem;  
  width: 1.5rem;
  margin-right: .2rem;
}

.medal-image {
  height: 1.5rem;
  width: 1.5rem;
}

.nav-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

.nav-block-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
  cursor: pointer;
  margin-top: 1rem;  
  margin-bottom: .5rem;
}

.nav-block-section-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 5rem;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

.nav-title {
  font-size: 1em;
  color: #c4b4d1;
  margin-left: .5rem;
}

.nav-title-active {
  font-weight: 800;
}

.nav-title:hover {  
  color: #e29de4;
}

.nav-title-weekly {
  font-size: 1em;
  color: #c4b4d1;
  margin-left: 3.5rem;
  margin-top: -1rem;
}

.nav-title-active-weekly {
  font-weight: 800;
}

.nav-title-weekly:hover {  
  color: #e29de4;
}

.link, .link:focus, .link:visited  {
    text-decoration: none;
}

.logout-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;

  margin-top: 5em;  
  margin-left: 1.5rem;  
}

.logout-icon {
  width: 2rem;
  height: 2rem;
  fill: #c4b4d1;
}

.refresh-icon {
  width: 2rem;
  height: 2rem;
  fill: #c4b4d1;  
}


@keyframes single-spin {
  from { transform: rotate(0deg) }
  to { transform: rotate(180deg) }
}
.refresh-icon:active {
  animation-name: single-spin;
  animation-duration: .5s;
  animation-iteration-count: 1;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: ease-in;
}

.mode-block {
  display: flex;
  flex-direction: column;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #c4b4d1;
  border-radius: .1rem;
}

.mode-title {
  font-size: 1.5em;
  font-weight: 800;
  color: #c4b4d1;    
}

.mode-radio-group {
  display: flex;
  flex-direction: column;  
  padding-bottom: 1rem;
}

.mode-radio-holder {
  display: flex;
  flex-direction: row;
  align-items: center;  

  margin-bottom: .5rem;
  cursor: pointer;
}

.radio-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: #c4b4d1; 
  margin-right: .5rem;  
}

.mode-radio-text {
  font-size: 1em;  
  color: #c4b4d1; 
}

.medal-icon {
  fill: #5d83d4;
  height: 1.5rem;
  width: 1.5rem;
}



.orbatroid-block {
  display: flex;
  flex-direction: column;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 2px solid #c4b4d1;
  border-radius: .1rem;
}

.orbatroid-title {
  font-size: 1em;  
  color: #c4b4d1;    
}

.orbatroid-radio-group {
  display: flex;
  flex-direction: column;  
  padding-bottom: 1rem;
  align-items: flex-start;  
}

.orbatroid-group {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.orbatroid-icon {
  height: 5rem;  
}

.orbatroid-text {
  font-size: 1em;  
  color: orange; 
  font-weight: 800;
  margin-bottom: .5rem;
}

.orbatroid-step {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: .5rem;
  text-align: center;
}

.orbatroid-step-title {
  font-size: 1.5em;  
  color: #c4b4d1; 
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.orbatroid-date {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: 1rem;
}

.community-icon {
  height: 8rem;  
  margin-bottom: .5rem;
}