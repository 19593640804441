.modal {
    position: fixed;
    z-index: 500;
    background-color: transparent;
    width: 100%;
    border: none;
    left: 0;      
    top: 0px;
    transition: all 0.3s ease-out;
}

.modal-instant {  
  position: fixed;
  z-index: 500;
  background-color: transparent;
  width: 80%;
  border: none;
  left: 10%;      
  top: 0px;    
}

