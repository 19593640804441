.main-content {  
  display: flex;
  flex-direction: row;
  flex-grow: .1;
}

.center-content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;  
}

.mission-holder {
  display:flex;
  flex-direction: column;    
}

.battle-top {  
  color: #c4b4d1;  
  font-size: 2em;
  font-family: 'VoyagerHeavy';

  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: .5rem;
  margin-top: 2rem;
}

.mission-manage-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'VoyagerHeavy';    
    
  position: absolute;
  bottom: 0rem;
  right: .5rem;
}

.mission-manage-button:hover {
  background-color: #6cc0ee;
}

.leaderboard-top-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  width: 74rem;
}

.details-block {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #c4b4d1;
  border-radius: .1rem;
  width: 100%;
}

.details-column {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.details-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.detail-title {
  font-size: 1.5em;  
  color: #c4b4d1;
  font-weight: 800;
  font-family: 'HelvCC';  
}

.detail-value {
  display: flex;
  flex-direction: column;
}



.checkbox-holder {
  display: flex;
  flex-direction: row;  
  align-items: center;
}

.checkbox-icon {  
  width: 1.5rem;
  height: 1.5rem;  
  fill: #c4b4d1;
  margin-right: .5rem;
}

.checkbox-message {  
  font-size: 1.5em;
  font-family: 'HelvCC'; 
  color: #c5b0d9;    
  text-align: center;  
  margin-top: .5rem;
}

.rarity-text-common {
  color: #9584a6;  
  font-weight: 800;
}

.rarity-text-rare {
  color: #5d83d4;  
  font-weight: 800;
}

.rarity-text-epic {
  color: #710774; 
  font-weight: 800; 
}

.rarity-text-legendary {
  color: #e08d38; 
  font-weight: 800; 
}


.link, .link:focus, .link:visited  {
  text-decoration: none;
}
  
.stream-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 1rem .5rem 1rem;  
  cursor:pointer;
    
  margin-top: 2rem;   
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;  
  height: 2.5rem;
}

.stream-button:hover {
  background-color: #6cc0ee;
}

.stream-icon {
  fill: white;
  height: 1rem;
  width: 1rem;
  margin-right: .5rem;
}

.stream-text {
  color: white;
  font-size: 1em;    
  font-family: 'VoyagerHeavy';    
}

.stream-display {
  display: flex;
  flex-direction: column;
}

input {
  border: 5px solid #c5b0d9;   
  padding: 15px;
  background: rgba(255,255,255,0);
  margin: 0 0 10px 0;
  color: #c5b0d9;
  font-size: 1.5em;
}