body {
  margin: 0;
  font-family: 'EquinoxBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14121d;
  background-image: url('https://cdn.cosmicclash.io/general/bg-main-grey-5.jpg');
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'EquinoxBold';
  src: local('EquinoxBold'), url(./assets/fonts/EquinoxBold.otf) format('opentype');
}

@font-face {
  font-family: 'EquinoxRegular';
  src: local('EquinoxRegular'), url(./assets/fonts/EquinoxRegular.otf) format('opentype');
}

@font-face {
  font-family: 'VoyagerHeavy';
  src: local('VoyagerHeavy'), url(./assets/fonts/VoyagerHeavy.otf) format('opentype');
}

@font-face {
  font-family: 'VoyagerLight';
  src: local('VoyagerLight'), url(./assets/fonts/VoyagerLight.otf) format('opentype');
}

@font-face {
  font-family: 'HelvCC';
  src: local('HelvCC'), url(./assets/fonts/HelveticaNeueLTStd-Th.otf) format('opentype');
}

.react-confirm-alert-overlay {
  background: rgba(0,0,0,0.8) !important;
}

.grecaptcha-badge { visibility: hidden; }

#ual-button {
  background-color: #2c9fde !important;
  border: 1px solid white !important;
  padding: 1rem 4rem 1rem 4rem !important;  
  border-radius: 0 !important;
  cursor:pointer !important;
  color: white !important;
  font-size: 1.5em !important;  
  text-align: center !important;
  margin-bottom: 2rem !important;
  font-family: 'VoyagerHeavy' !important;
  margin-top: 3rem !important;
}

@media only screen and (max-width: 480px) {
  #ual-button {
    width: 225px !important;
  }
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}
