.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50rem;
  height: 40rem;

  position:relative;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  position: absolute;  
  top: 1rem;
  right: 1rem;
}

.popup-title {
  font-size: 3em;  
  color: #c5b0d9;  
  font-family: 'VoyagerHeavy';  
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.return-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 1.5rem .5rem 1.5rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;  
  font-family: 'VoyagerHeavy';  
}

.return-button:hover {
  background-color: #6cc0ee;
}

.return-button-log {    
  border: 1px solid white;
  padding: .5rem 1.5rem .5rem 1.5rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center; 
  font-family: 'VoyagerHeavy';  
}

.return-button-log:hover {
  background-color: #6cc0ee;
}

.link, .link:focus, .link:visited  {
  text-decoration: none;
}


.sponsor-text {
  font-size: 1.8em;  
  color: #c5b0d9;   
  font-family: 'VoyagerHeavy'; 
}

.cc-section {
  display: flex; 
  flex-direction: column;
}

.cc-learn-more {
  font-size: 1.5em;  
  color: #c5b0d9;   
  margin-bottom: .5rem;
  margin-top: .5rem;
  font-family: 'VoyagerHeavy';
}

.sponsor-image {
  width: 90%;
  max-height: 20rem;
  cursor:pointer;
}

.cc-socials {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}

.cc-social {
  background-color: rgba(226,157,228,.2);
  cursor: pointer;
}

.cc-social-image {
  width: 5rem;
  height: 5rem;
}