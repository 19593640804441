.battle-row {
  background-color: rgba(226,157,228,.2);    
  border: 1px solid #8F73BE;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  width: 74rem;
  font-family: 'VoyagerHeavy';
}

.battle-results {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  width: 10rem;  
}

.battle-expand {  
  border: .1rem solid white;  
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .2rem;
  margin-right: .2rem;
  cursor: pointer;
}

.zoom-icon {
  fill: white;
  width: 1.5rem;
}

.battle-date {
  font-size: .8em;
  color: #c4b4d1;
  width: 16rem;
  margin-left: .5rem;
}

.simple-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 3rem;
}

.battle-vs {
  font-size: 0.6em;
  color: #c4b4d1;
  margin-right: .5rem;
  margin-left: .5rem;
  margin-top: .4rem;
}

.battle-type {
  font-size: 1em;
  color: #c4b4d1;
  
  text-align: center;
  padding: .2rem;
  margin-right: .2rem;
}

.enemy-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.battle-rematch {
  font-size: 0.6em;
  color: #c4b4d1;
  border: .1rem solid #c4b4d1;
  text-align: center;
  margin-right: .2rem;
  margin-left: 1rem;
  padding: .2rem;
}

.token-image {
  height: 2rem;    
  padding-top: .3rem;
  margin-right: .2rem;
}

.token-image-bw {
  height: 2rem;    
  padding-top: .3rem;
  margin-right: .2rem;
  filter: grayscale(100%);
}

.battle-attacker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.battle-name {
  font-size: 1em;
  color: #c4b4d1;  
}

.battle-fleets {
  display: flex;
  flex-direction: column;
}

.battle-fleet {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.battle-win {
  font-size: 1.2em;
  color: white;
  border: .1rem solid darkgreen;
  border-radius: 0.1rem;
    
  padding-left: 0.5rem;
  padding-right: 0.5rem;  
  padding-top: .5rem;
  padding-bottom: .5rem;
  
  width: 6rem;
  text-align: center;  
}

.battle-type-gold {
  font-size: 1em;
  color: #FFD700;
  
  text-align: center;
  padding: .2rem;
  margin-right: .2rem;
}

.battle-type-bronze {
  font-size: 1em;
  color: #cd7f32;
  
  text-align: center;
  padding: .2rem;
  margin-right: .2rem;
}

.battle-loss {
  font-size: 1.2em;
  color: white;
  border: .1rem solid darkred;
  border-radius: 0.1rem;
    
  padding-left: 0.5rem;
  padding-right: 0.5rem;  
  padding-top: .5rem;
  padding-bottom: .5rem;  
  width: 6rem;
  text-align: center;  
}

.link, .link:focus, .link:visited  {
  text-decoration: none;
}
  
.watch-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem .5rem .5rem .5rem;
  cursor:pointer;
  margin-left: .5rem;
  
  display: flex;
  flex-direction: row;
}

.watch-button:hover {
  background-color: #6cc0ee;
}

.watch-icon {
  fill: white;
  height: 1.5rem;
  width: 1.5rem;  
}

.replay-text {
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'VoyagerHeavy';  
  padding-top: .08rem;
}

.placeholder {
  width: 2.1rem;
}

.battle-right {
  display: flex;
  flex-direction: row;
  margin-left: auto;

  align-items: center;
}

.battle-result {
  display: flex;
  flex-direction: row;  
  margin-right: .5rem;

  align-items: center;
  font-size: 0.6em;
  color: #c4b4d1;
}

.arrow-up {
  height: 1.5rem;    
  fill: darkgreen;  
}

.arrow-down {
  height: 1.5rem;    
  fill: darkred;
}