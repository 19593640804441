.battle-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(193, 176, 211, 0.2);    
  border: 1px solid #8F73BE;
  color: #c4b4d1;
  margin-bottom: 1rem;
  margin-right: .1rem;
  padding: .5rem;  
}

.battle-side {
  display: flex;
  flex-direction: row;
  width: 12rem;
  align-items: center;
}

.battle-description {
  width: 100%;
  text-align: center;
  padding: 1rem;
}

.ship-holder {
  width: 6rem;
  height: 6rem;  
  margin: .5rem;
  border-radius: 0.5rem;
  border:none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: relative;
}

.ship-holder-common {
  border: 1px solid #9584a6;  
}

.ship-holder-rare {
  border: 1px solid #5d83d4;  
}

.ship-holder-epic {
  border: 1px solid #710774;  
}

.ship-holder-legendary {
  border: 1px solid #e08d38;  
}

.ship-holder-dead {
  border: 1px solid darkred;  
}

.ship-dead {
  filter: grayscale(100%);
}

.cross-icon {  
  fill: rgba(146,11,11,0.5);
  width: 6rem;
  height: 6rem;
}

.cross-holder {
  position: absolute;
  top: 0;
  right: 0;
}

.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;  
  padding-bottom: .2rem;
  width: 100%;
  align-items: center;
}

.top-row-common {
  background-color: #9584a6;  
  color: black;
}

.top-row-rare {
  background-color: #5d83d4;  
}

.top-row-epic {
  background-color: #710774;  
}

.top-row-legendary {
  background-color: #e08d38;  
}

.ship-level {
  font-size: .5rem;    
}

.ship-name {
  font-size: 1rem;  
  font-weight: 800;  
}

.ship-count {
  font-size: .5rem;    
}

.middle-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
}

.side-row {
  display: flex;
  flex-direction: column;  
  justify-content: space-around;  
}

.stat-row {
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin-bottom: .5rem;
}

.stat-row:last-child {
  margin-bottom: 0;
}

.stat-image {
  width: 2rem;
  margin-right: .5rem;
}

.stat-base-text {  
  margin-right: .5em;
  font-size: 1.2em;
}

.empty-block {
  width: 10rem;
}
