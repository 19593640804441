.side-drawer {
    position: fixed;
    width: 50vw;    
    height: 100%;
    left: 50vw;
    top: 0;
    z-index: 200;
    background-color: #3d2e4d;
    padding: 1.2rem 1.6rem;
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2+ */
    transition: transform 0.3s ease-out;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.open {
    transform: translateX(0);
}

.close {
    transform: translateX(150%);
}

.close-button {
  width: 2rem;
  height: 2rem;
  fill: #bbaccd;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}

.content-column {
  width: 20rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 3rem;
  margin-top: 2rem;
}

@media (orientation: landscape) {
  .content-column {
    margin-top: .5rem;
  }
}

.button-group {
  display: flex;
  margin-bottom: .5rem;
}

@media (orientation: landscape) {
  .button-group {
    margin-bottom: 0;
  }
}

.button-group-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: .5rem;
}

@media (orientation: landscape) {
  .button-group-bottom {
    flex-direction: row;
    margin-top: .5rem;
  }
}

.button-divider {
  display: none;
}

@media (orientation: landscape) {
  .button-divider {
    display: inline-block;
    color: white;
    font-size: 1.4rem;    
    font-family: 'HelvCC';
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.ranking-label {
  font-size: 1.4rem;
  color: white;
  font-family: 'HelvCC';
  text-decoration: underline;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

@media (orientation: landscape) {
  .ranking-label {
    margin-top: .5rem;
  }
}

.button-label {
    font-size: 1.4rem;
    color: #bbaccd;
    font-family: 'HelvCC';
    text-decoration: none;
}

.button-label:visited {
  color: #bbaccd;
  text-decoration: none;
}

.link, .link:focus, .link:visited  {
  text-decoration: none;
}
