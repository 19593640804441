.battle-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  margin-right: .1rem;
  width: 5.5rem;
}

.battle-side {
  display: flex;
  flex-direction: row;  
  align-items: center;
}


.ship-holder {
  width: 5rem;
  height: 5rem;  
  border:none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: relative;
}

.ship-holder-common {
  border: 1px solid #9584a6;  
}

.ship-holder-rare {
  border: 1px solid #5d83d4;  
}

.ship-holder-epic {
  border: 1px solid #710774;  
}

.ship-holder-legendary {
  border: 1px solid #e08d38;  
}

.ship-holder-dead {
  border: 1px solid darkred;  
}

.ship-dead {
  filter: grayscale(100%);
}

.cross-icon {  
  fill: rgba(146,11,11,0.5);
  width: 5rem;
  height: 5rem;
}

.cross-holder {
  position: absolute;
  top: 0;
  right: 0;
}

.middle-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.empty-block {
  width: 10rem;
}

.tip-text {
  font-family: "EquinoxBold" !important;
  font-weight: 800 !important;  
}

.level-holder {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: .5em;
  color: white;
  
  font-weight: 800;
  width: 1rem;
  height: 1rem;
  padding-top: .1rem;
  text-align: center;
}

.level-holder-common {
  background-color: #9584a6;  
}

.level-holder-rare {
  background-color: #5d83d4;  
}

.level-holder-epic {
  background-color: #710774;  
}

.level-holder-legendary {
  background-color: #e08d38;  
}