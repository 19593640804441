.wrapper {
  height: 100vh;  
  background-color: transparent;  
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.log-holder {
  background-color: rgba(26, 11, 52,1);
  margin: .5rem;  
  width: 100vw;
  max-width: 60rem;  
  height: 100vh;
    
  display: flex;
  flex-direction: column;  
}

.log-contents {
  overflow-y: scroll;
  overflow-x: hidden;
  
  display: flex;
  flex-direction: column;  
}

.log-title {
  width: 100%;
  color: #c4b4d1;
  font-size: 2rem;  
  padding: .5rem;
  font-family: 'VoyagerHeavy';
  text-align: center;
}

.log-players {
  display: flex;
  align-items: center;    
}

.log-caption {  
  width: 100%;
  color: #c4b4d1;
  font-size: 1rem;  
  padding: .5rem;
  font-family: 'VoyagerHeavy';
  text-align: center;
}

.log-line {
  color:white;
  font-size: 1rem;
  padding: .5rem;
}

.button-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 7rem;
}

@media (orientation: landscape) {
  .button-holder {    
    padding-bottom: 2.5rem;
  }
}

.close-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 1rem .5rem 1rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;  
  font-family: 'VoyagerHeavy';  
  margin-left: .5rem;
  margin-right: .5rem;
}

.close-button:hover {
  background-color: #6cc0ee;
}

.checkbox-message {
  font-size: 1em;  
  color: #c5b0d9;    
  text-align: center;
  margin-right: .5rem;
}

.checkbox-icon {  
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
  fill: #c4b4d1;
}

.checkbox-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
  align-items: center;
}