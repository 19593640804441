.top-nav {
  display: flex;
  flex-direction: row;  
  width: 100vw;  
  justify-content: space-between;
  
}

.player-block {
  display: flex;
  flex-direction: row;  

  margin-top: .5rem;
  margin-left: .5rem;
  margin-bottom: 1rem;
}

@media (orientation: landscape) {
  .player-block {
    margin-bottom: 0rem;
  }
}

.player-info {
  display: flex;
  flex-direction: column;

  margin-left: 1rem;  
}

.player-name-box {
  display: flex;
  flex-direction: row;

  margin-bottom: .5rem;
}

.player-name {
  font-size: 1em;  
  color: #c4b4d1;
  font-family: 'VoyagerHeavy';
}

.logout-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  margin-left: .5rem;
  margin-top: .1rem;
}

.player-stats {  
  font-size: 1em;
  color: #c4b4d1;
  
  display: flex;
  flex-direction: row;  
}

.player-stats-box {
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.token-image {
  height: 1.5rem;  
  width: 1.5rem;
  margin-right: .2rem;
}

.medal-image {
  height: 1.5rem;
  width: 1.5rem;
}

.medal-icon {
  fill: #5d83d4;
  height: 1.5rem;
  width: 1.5rem;
}

.toggle {
  margin-top: .5rem;
}

.toggle-lines {
  width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: .1rem 0;    
  cursor: pointer;  
}

.toggle-lines div {
  width: 60%;
  height: .5rem;
  background-color: #bbaccd;            
}

.toggle-lines :not(:last-child) {
  margin-bottom: .5rem;
}

.nav-icon {
  width: 3rem;
  height: 3rem;  
}

.refresh-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  margin-left: .75rem;
}


@keyframes single-spin {
  from { transform: rotate(0deg) }
  to { transform: rotate(180deg) }
}
.refresh-icon:active {
  animation-name: single-spin;
  animation-duration: .5s;
  animation-iteration-count: 1;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: ease-in;
}

.game-mode-button {
  border: 2px solid #c4b4d1;
  border-radius: .1rem;
  margin-left: .5rem;
  cursor: pointer;
  padding: .1rem;

  font-family: 'VoyagerHeavy';
  font-size: .5em;  
  font-weight: 800;
  color: #c4b4d1;
  padding-left: .5rem;
  padding-right: .5rem;
}


