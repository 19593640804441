.wrapper {
  height: 100vh;  
  background-color: transparent;  
  color: #c4b4d1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'HelvCC';
  font-weight: 800;
  cursor: pointer;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;  
  
  justify-content: space-between;
  align-items: center;
  width: 50rem;
  height: 30rem;  
}

.top-row {
  width: 100%;
  position: relative;  
  margin-top: 1rem;
}

.text-collected {
  color: #c4b4d1;
  text-align: center;
  width: 100%;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  position: absolute;
  right: 1rem;
  top: 0;
}

.bottom-row {
  display: flex;
  flex-direction: row;

  width: 50rem;
  margin-top: 1rem;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 25rem;  
  align-items: center;
}

.ship-image {  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 25rem;
  flex-grow: 1;
}

.spritesheet-table {  
  display: table;
  height: 100%;
}

.spritesheet-wrapper {  
  display: table-cell;
  vertical-align: middle;
}

.spritesheet-container {  
  overflow: hidden;
  position: relative;
  width: 25rem;
}

@keyframes moveSpritesheet {
  from {
      transform: translate(0px,0)
  }
  to {
      transform: translate(-100%,0)
  }
}

.ship-spritesheet-base {
  animation: moveSpritesheet 1s steps(8) infinite;
  max-height: 25rem;
}

.ship-spritesheet-8 {
  animation-timing-function: steps(8);  
}
.ship-spritesheet-14 {
  animation-timing-function: steps(14);  
}
.ship-spritesheet-19 {
  animation-timing-function: steps(19);  
}
.ship-spritesheet-12 {
  animation-timing-function: steps(12);  
}
.ship-spritesheet-13 {
  animation-timing-function: steps(13);  
}
.ship-spritesheet-17 {
  animation-timing-function: steps(17);  
}
.ship-spritesheet-21 {
  animation-timing-function: steps(21);  
}
.ship-spritesheet-18 {
  animation-timing-function: steps(18);  
}
.ship-spritesheet-22 {
  animation-timing-function: steps(22);  
}


.ship-name {
  font-size: 2em;
  font-weight: 800;
}

.right-side {
  width: 50%;
  margin-left: 1rem;

  display: flex;
  flex-direction: column;
}

.stats-title {
  font-size: 1.5em;
  font-weight: 600;
}

.stats-block {
  display: flex;
  flex-direction: column;  
}

.stat-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem;
}

.stat-image {
  width: 1.5rem;
}


.ship-rarity {  
  font-weight: 800;  
  margin-top: .2rem;
  font-size: .8em;
}

.ship-rarity-common {
  color: #9584a6;  
}

.ship-rarity-rare {
  color: #5d83d4;  
}

.ship-rarity-epic {
  color: #710774;  
}

.ship-rarity-legendary {
  color: #e08d38;  
}

.stat-details {
  font-size: 1.5em;
  margin-left: 1rem;

  display: flex;
  flex-direction: row;
  margin-top: .2rem;
}

.stat-base-text {
  color: #c4b4d1;
  font-size: .8em;
}

.stat-upgrade-text {
  color: lightgreen;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.stat-red-text {
  color: red;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.stat-powerup-text {
  color: #5d83d4;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.stat-avatar-text {
  color: yellow;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.description-text {
  font-size: 1em;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.tip-text {
  font-family: "EquinoxBold" !important;
  font-weight: 800 !important;  
}

