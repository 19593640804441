.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 60rem;
  height: 100vh;
  max-height: 30rem;

  padding-top: 1rem;
  padding-bottom: 2rem;

  position: relative;
}

@media (orientation: landscape) {
  .popup-content-image {  
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  position: absolute;
  top: 5rem;
  right: .5em;
}

@media (orientation: landscape) {
  .cross-holder {  
    top: 4rem;
    right: 2rem;
  }
}

.popup-title {
  font-size: 2em;  
  color: #c5b0d9;  
  font-family: 'VoyagerHeavy';  
  text-align: center;  
  margin-bottom: 2rem;  
  width: 90%;
}

@media (orientation: landscape) {
  .popup-title{  
    width: 100%;
  }
}

.winner-result {
  display: flex;
  flex-direction: row;
}

.winner-text {
  font-size: 2em;
  font-weight: 800;
  color: #c5b0d9;  
  text-align: center;  
}

.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.return-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 1rem .5rem 1rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'VoyagerHeavy';
  margin-top: 3rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.return-button:hover {
  background-color: #6cc0ee;
}

.return-button-log {    
  border: 1px solid white;
  padding: .5rem 1rem .5rem 1rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'VoyagerHeavy';
  margin-top: 3rem;
  margin-right: .5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.return-button-log:hover {
  background-color: #6cc0ee;
}

.waiting-content {
  font-size: 3em;
  font-weight: 500;
  color: white;
  padding: 1rem;
}

.link, .link:focus, .link:visited  {
  text-decoration: none;
}

.medal-image {
  height: 3rem;  
}

.medal-icon {
  fill: #5d83d4;
  height: 3rem;  
}

.earn-section {
  display: flex;
  flex-direction: row;  
}

.tokens-text {
  font-size: 2em;
  font-weight: 800;
  color: #c5b0d9;  
  margin-right: 1rem;
}

.token-result {
  display: flex;
  flex-direction: row;
}

.tokens-value {
  font-size: 2em;
  font-weight: 800;
  color: white;  
  margin-right: .5rem;
}

.token-image {
  height: 2.5rem;
}

.sponsor-text {
  font-size: 1.5em;  
  color: #c5b0d9;   
  font-family: 'VoyagerHeavy'; 
  margin-bottom: .5rem;
  margin-top: .5rem;
}

.cc-section {
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.cc-learn-more {
  font-size: 1.5em;  
  color: #c5b0d9;   
  margin-bottom: .5rem;
  margin-top: .5rem;
  font-family: 'VoyagerHeavy';
}

.sponsor-image {  
  max-height: 7rem;
  cursor:pointer;
}

.sponsor-section {
  display: flex;
  flex-direction: column;
}

.sponsor-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (orientation: landscape) {
  .sponsor-section {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .sponsor-image {      
    max-height: 6rem;
  }
}

.cc-socials {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.cc-social {
  background-color: rgba(226,157,228,.2);
  cursor: pointer;
}

.cc-social-image {
  width: 5rem;
  height: 5rem;
}