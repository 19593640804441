.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 60rem;
  height: 100vh;
  max-height: 40rem;

  padding-bottom: 1rem;

  position: relative;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
  position:absolute;
  right: .5rem;
  top: .5rem;
}

@media (orientation: landscape) {
  .popup-content-image {
    padding-bottom: 3rem;
  }
  
  .cross-icon {  
    top: 2rem;
    right: 1rem;
  }
}

.cross-holder {
  margin-left: auto;
  margin-top: 1rem;
  margin-right: 1rem;
}

.popup-title {
  font-size: 3em;  
  color: #c5b0d9;  
  font-family: 'VoyagerHeavy';  
  margin-bottom: 6rem;
  margin-top: 1rem;
}

.popup-title-small {
  font-size: 2em;  
  color: #c5b0d9;  
  font-family: 'VoyagerHeavy';  
  margin-bottom: 1rem;
  margin-top: 1rem;
}


.message-text-top {
  font-size: 1.5em;  
  color: #c5b0d9;  
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
}

.message-text {
  font-size: 1.5em;  
  color: #c5b0d9;  
  margin-bottom: 3rem;
  padding: 1rem;
  text-align: center;
}

@media (orientation: landscape) {
  .message-text {    
    margin-bottom: 0rem;
  }
}

.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.return-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 4rem .5rem 4rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'VoyagerHeavy';
  margin-top: 5rem;
}

.return-button:hover {
  background-color: #6cc0ee;
}

.atomic-button {
  padding: 1rem 2rem 1rem 2rem;
  font-family: 'VoyagerHeavy';
  font-weight: 800;
  font-size: 1.5em;
  width: 18rem;
  margin-bottom: 1rem;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.atomic-text {
  font-family: 'VoyagerHeavy';
  font-weight: 800;
  font-size: 1em;
}

.atomic-icon {
  fill: black;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
  margin-top: .2rem;
}


.button-holder {
  display: flex;
  flex-direction: column;
}

@media (orientation: landscape) {
  .button-holder {    
    flex-direction: row;
  }

  .atomic-button {
    margin-right: 1rem;
  }
}


.logout-block {  
  padding: 1rem 2rem 1rem 2rem;
  font-family: 'VoyagerHeavy';
  font-weight: 800;
  font-size: 1.5em;
  width: 18rem;
  margin-bottom: 1rem;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logout-text {    
  font-family: 'VoyagerHeavy';
  font-weight: 800;
  font-size: 1em;
  margin-right: 1rem;
}

.logout-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: black;
  margin-top: .5rem;
}